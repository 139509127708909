<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <a :href="form.msUrl" target="_blank">
        <el-button type="primary">Просмотреть в МС </el-button>
      </a>
      <el-button type="primary" @click="submit"> Сохранить  </el-button>
      <el-button type="primary" @click="submitAndExit"> Сохранить и вернуться </el-button>
    </div>
    <el-form
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="Фото" prop="images">
        <Uploader
          :limit="10"
          :files="form.images ?? []"
          multiple
          @upload="uploadImages"
        />
      </el-form-item>
      <el-form-item label="Имя" prop="name">
        <el-input
          v-model="form.name"
          @focus="!form.slug ? handleNameInput(form.name) : ''"
        ></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Код МойСклад" prop="msCode">
        <el-input v-model="form.msCode"></el-input>
      </el-form-item>
      <el-form-item label="Приоритет" prop="priority">
        <el-input-number v-model="form.priority" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <TextEditor :value.sync="form.description" />
      </el-form-item>
      <el-form-item label="Условия аренды">
        <TextEditor :value.sync="form.rentTerms" />
      </el-form-item>
      <el-form-item label="Артикул" prop="vendorCode">
        <el-input v-model="form.vendorCode"></el-input>
      </el-form-item>
      <el-form-item label="Объем, мл" prop="volume">
        <el-input-number v-model="form.volume" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Цена за день" prop="basicPricePerDay">
        <el-input-number
          v-model="form.basicPricePerDay"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Доп. Цена за день" prop="additionalPricePerDay">
        <el-input-number
          v-model="form.additionalPricePerDay"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="Компенсация боя">
        <el-input-number v-model="form.damageFee" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Кол-во на складе" prop="stocks">
        <el-input-number v-model="form.stocks" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Активный" prop="isActive">
        <el-checkbox v-model="form.isActive" />
      </el-form-item>
      <el-form-item label="Категории" prop="categories">
        <el-select
          v-model="form.categories"
          value-key="id"
          filterable
          clearable
          remote
          multiple
          placeholder="Выбрать"
        >
          <el-option
            v-for="(item, index) in categories"
            :key="index.id"
            :label="item.name"
            :value="item"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Бренд" prop="brandId">
        <el-select
          v-model="form.brandId"
          placeholder="Выберите"
          style="width: 100%"
          v-el-select-loadmore="loadMoreBrands"
          filterable
          remote
          :remote-method="searchBrands"
          :loading="brandsLoading"
        >
          <el-option
            v-for="item in brands"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Ассортименты" prop="assortmentIds">
        <el-select
          v-model="form.assortments"
          value-key="id"
          filterable
          v-el-select-loadmore="loadMoreAssortments"
          clearable
          remote
          multiple
          :remote-method="searchAssortments"
          :loading="assortmentsLoading"
          placeholder="Выберите"
        >
          <el-option
            v-for="item in assortments"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Сопутствующие товары" prop="relatedProducts">
        <el-select
          :value="form.relatedProducts"
          value-key="id"
          filterable
          v-el-select-loadmore="loadMoreAddwineProducts"
          remote
          multiple
          clearable
          :remote-method="searchAddwineProducts"
          :loading="addwineProductsLoading"
          placeholder="Выберите"
          @change="checkAdditionalProduct($event)"
          @remove-tag="removeAdditional($event)"
        >
          <el-option
            v-for="item in addwineProducts"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
            <span>{{ item.name }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <p>Аттрибуты</p>
      <el-form-item
        label="Страна производства"
        prop="attributes.countryOfOrigin"
      >
        <el-input v-model="form.attributes.countryOfOrigin"></el-input>
      </el-form-item>
      <el-form-item label="Штрих-код" prop="attributes.barcode">
        <el-input v-model="form.attributes.barcode"></el-input>
      </el-form-item>
      <el-form-item label="Материал" prop="attributes.material">
        <el-input v-model="form.attributes.material"></el-input>
      </el-form-item>
      <el-form-item label="Цвет" prop="attributes.color">
        <el-input v-model="form.attributes.color"></el-input>
      </el-form-item>
      <el-form-item label="Размеры, см" prop="attributes.dimensions">
        <el-input v-model="form.attributes.dimensions"></el-input>
      </el-form-item>
      <el-form-item
        label="Транспортная единица"
        prop="attributes.transportUnit"
      >
        <el-input v-model="form.attributes.transportUnit"></el-input>
      </el-form-item>
      <el-form-item label="Комплектация" prop="attributes.equipment">
        <el-input v-model="form.attributes.equipment"></el-input>
      </el-form-item>
      <el-form-item label="Диаметр, см" prop="attributes.diameter">
        <el-input v-model="form.attributes.diameter"></el-input>
      </el-form-item>
      <el-form-item label="Bec, кг" prop="attributes.weight">
        <el-input v-model="form.attributes.weight"></el-input>
      </el-form-item>
      <SeoBlock
        is-with-keywords
        is-add-event
        has-set-default-flag
        :setDefault.sync="form.seo.setDefault"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage.origin"
        :keywords.sync="form.seo.keywords"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>
<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import { transliterate } from '@/helpers/slug'
import { debounce } from '@/helpers/debounce.js'

import { SEO_TYPES } from '@/constants/seo'

import TextEditor from '@/components/atoms/TextEditor.vue'
import Uploader from '@/components/moleculs/Uploader.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'

export default {
  components: { TextEditor, Uploader, SeoBlock },
  mixins: [notifications],
  delivery,
  SEO_TYPES,
  data() {
    return {
      brands: [],
      brandsLoading: false,
      brandsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      assortments: [],
      assortmentsLoading: false,
      assortmentsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      categories: [],
      addwineProducts: [],
      addwineProductsLoading: false,
      addwineProductsOptions: {
        limit: 20,
        page: 1,
        total: 0,
        search: '',
      },
      form: {
        name: '',
        slug: '',
        msCode: '',
        description: '',
        vendorCode: '',
        volume: 0,
        basicPricePerDay: 0,
        additionalPricePerDay: 0,
        damageFee: 0,
        stocks: 0,
        isActive: false,
        categories: [],
        images: [],
        priority: 0,
        msUrl: '',
        rentTerms: '',
        relatedProducts: [],
        assortments: [],
        attributes: {
          countryOfOrigin: '',
          barcode: '',
          material: '',
          color: '',
          dimensions: '',
          transportUnit: '',
          equipment: '',
          diameter: '',
          weight: '',
        },
        seo: {
          setDefault: false,
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: {
            id: null,
            caption: '',
            origin: '',
            order: 1,
          },
          keywords: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        msCode: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        vendorCode: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        volume: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        basicPricePerDay: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        additionalPricePerDay: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        stocks: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        isActive: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        brandId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        attributes: {
          countryOfOrigin: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          barcode: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          material: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          color: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          dimensions: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          transportUnit: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          equipment: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          diameter: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          weight: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          keywords: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: {
            origin: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'change',
              },
            ],
          },
        },
      },
    }
  },
  async created() {
    await this.getProducts()
    await Promise.all([
      this.getCategories(),
      this.getProduct(),
      this.getBrands(),
      this.getAssortments(),
    ])
    this.brandSearch = debounce(this.getBrands, 500)
    this.productsSearch = debounce(this.getProducts, 500)
    this.assortmentsSearch = debounce(this.getAssortments, 500)
  },
  methods: {
    removeAdditional(product) {
      this.form.relatedProducts = this.form.relatedProducts.filter(
        (relatedProduct) => relatedProduct.id !== product.id,
      )
    },
    checkAdditionalProduct(products) {
      const productWithOutStocks = products.find(
        (item) => !item.stocks?.[0].stock,
      )

      if (productWithOutStocks) {
        this.showNotification(
          `Сопутствующий товар ${productWithOutStocks.name} не в наличии `,
          'error',
        )
        return
      }

      this.form.relatedProducts = [...products]
    },

    async getBrands() {
      this.brandsLoading = true
      const { value, error } =
        await delivery.AddeventServiceCore.BrandsActions.getList(
          this.brandsOptions,
        )
      this.brandsLoading = false
      if (error) return
      this.brands = [...this.brands, ...value.data]
      this.brandsOptions.total = value.meta.count
    },
    async getAssortments() {
      this.assortmentsLoading = true

      const { value, error } =
        await delivery.AddeventServiceCore.AssortmentsActions.getList(
          this.assortmentsOptions,
        )
      this.assortmentsLoading = false

      if (error) return
      this.assortments = [...this.assortments, ...value.data]
      this.assortmentsOptions.total = value.meta.count
    },
    async getProducts() {
      this.addwineProductsLoading = true

      const { value, error } =
        await delivery.ProductsCore.ProductsActions.getList({
          ...this.addwineProductsOptions,
          isBundle: false,
        })
      this.addwineProductsLoading = false

      if (error) return
      this.addwineProducts = [...this.addwineProducts, ...value.data]
      this.addwineProductsOptions.total = value.meta.count
    },
    async getCategories() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.CategoriesActions.getList()

      loading.close()

      if (error) {
        return
      }

      this.categories = value?.data ?? []
    },
    async getProduct() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getOne(
          this.$route.params.id,
        )

      loading.close()

      if (error) {
        return
      }

      this.form = {
        ...value,
        images: value?.images ?? [],
        seo: value?.seo
          ? {
              ...value?.seo,
              setDefault: value?.seo?.type === SEO_TYPES.TEMPLATE,
              ogImage: value?.seo?.ogImage
                ? {
                    ...value?.seo?.ogImage,
                    origin: value?.seo?.ogImage?.original,
                  }
                : this.form?.seo?.ogImage,
            }
          : {
              ...this.form?.seo,
              setDefault: false,
            },
      }

      if (value?.relatedProducts?.length) {
        value.relatedProducts.forEach((e) => {
          if (this.addwineProducts?.find((product) => product?.id === e?.id))
            return
          this.addwineProducts = [...(this.addwineProducts ?? []), e]
        })
      }
    },
    async submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        const data = {
          ...this.form,
          categories: this.form.categories?.map((c) => c?.id) ?? [],
          images:
            this.form.images?.map((e, index) => ({
              id: e?.id ?? null,
              caption: e.caption,
              origin: e.original,
              order: index + 1,
            })) ?? [],
          relatedProductsIds: this.form.relatedProducts?.map((e) => e.id) ?? [],
          assortmentIds: this.form.assortments?.map((e) => e.id) ?? [],
          setDefault: this.form?.seo?.setDefault,
          seo: this.form?.seo?.setDefault
            ? null
            : {
                ...this.form.seo,
                type: SEO_TYPES.DEFAULT,
                ogImage: {
                  ...this.form?.seo?.ogImage,
                  caption: this.form?.seo?.title,
                },
              },
        }

        const loading = this.$loading({
          lock: true,
        })

        const { error } =
          await delivery.AddeventServiceCore.ProductsActions.update(
            this.$route.params.id,
            data,
          )

        loading.close()

        if (error) {
          this.showNotification('Ошибка обновления продукта', 'error')
          return
        }

        this.showNotification('Продукт успешно отредактирован', 'success')
        this.$router.go(-1)
      })
    },
    async submitAndExit() {
      await this.submit()
      this.$router.go(-1)
    },
    loadMoreBrands() {
      const { page, limit, total } = this.brandsOptions
      if (limit * page < total) {
        this.brandsOptions.page++
        this.getBrands()
      }
    },
    loadMoreAssortments() {
      const { page, limit, total } = this.assortmentsLoading
      if (limit * page < total) {
        this.assortmentsLoading.page++
        this.getAssortments()
      }
    },
    loadMoreAddwineProducts() {
      const { page, limit, total } = this.addwineProductsOptions
      if (limit * page < total) {
        this.addwineProductsOptions.page++
        this.getProducts()
      }
    },
    async searchBrands(search) {
      this.brandsOptions.page = 1
      this.brandsOptions.search = search
      this.brandsLoading = true
      this.brands = []
      await this.brandSearch()
    },
    async searchAssortments(search) {
      this.assortmentsOptions.page = 1
      this.assortmentsOptions.search = search
      this.assortmentsLoading = true
      this.assortments = []
      await this.assortmentsSearch()
    },
    async searchAddwineProducts(search) {
      this.addwineProductsOptions.page = 1
      this.addwineProductsOptions.search = search
      this.addwineProductsLoading = true
      this.addwineProducts = []
      await this.productsSearch()
    },
    uploadImages(images) {
      this.form.images = images
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
  },
}
</script>
<style lang="scss" module>
.page {
  padding: 1rem;

  .wrapper {
    @include stickyWrapper;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .form {
    margin-top: 2rem;
    & > div > label {
      text-align: left;
    }

    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
}
</style>
